<template>
   <AmcText>
      <button data-bs-toggle="modal" data-bs-target="#editValue">
         <IconEdit />
      </button>
      <div
         class="modal fade p-0"
         id="editValue"
         data-bs-backdrop="static"
         data-bs-keyboard="false"
         tabindex="-1"
         aria-labelledby="staticBackdropLabel"
         aria-hidden="true"
      >
         <AmcText class="modal-dialog modal-dialog-centered">
            <AmcText class="modal-content">
               <AmcText class="modal-body px-5 py-5">
                  <slot name="modalBody"></slot>
               </AmcText>
               <AmcText class="modal-footer d-flex justify-content-around">
                  <button type="button" class="btn btn-light rounded-pill px-5 border" data-bs-dismiss="modal">
                     Vazgeç
                  </button>
                  <button
                     @click="$emit('edit', index)"
                     type="button"
                     class="btn btn-primary rounded-pill px-5 text-white"
                     data-bs-dismiss="modal"
                  >
                     Güncelle
                  </button>
               </AmcText>
            </AmcText>
         </AmcText>
      </div>
   </AmcText>
</template>

<script>
import IconEdit from '../icons/IconEdit';

export default {
   name: 'AmcEditModal',
   components: {
      IconEdit,
   },
};
</script>

<style scoped>
svg:hover {
   stroke: #137af6;
}
</style>
